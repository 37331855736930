<template>
  <div>

    <div class="contents">
      <div class="tableSearch">
        <div class="classObj">
          <el-button @click="shouquan" type="primary" icon="el-icon-plus"
            >添加授权渠道</el-button
          >
        </div>
        <el-form
          :inline="true"
          ref="searchForm"
          :model="searchForm"
          label-width="100px"
        >
          <el-form-item label="渠道名称" style="margin-bottom: 0px">
            <el-input
              v-model="searchForm.name"
              clearable
              style="width: 190px"
              placeholder="请输入渠道名称"
            ></el-input>
          </el-form-item>
          <el-button @click="getTopSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
        </el-form>
      </div>

      <div class="mainbox">
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
          stripe
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            width="50"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="name" label="渠道名称" width="200">
          </el-table-column>
          <el-table-column
            prop="platform"
            label="平台"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              {{ platformArr[scope.row.platform] }}
            </template>
          </el-table-column>

          <el-table-column
            prop="tokenExpireTime"
            label="授权有效期至"
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.tokenExpireTime }}
            </template>
          </el-table-column>
          <el-table-column
            prop="authStatus"
            label="授权状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.authStatus == 0">{{
                statusArr[scope.row.authStatus]
              }}</el-tag>
              <el-tag type="success" v-if="scope.row.authStatus == 1">{{
                statusArr[scope.row.authStatus]
              }}</el-tag>
              <el-tag type="warning" v-if="scope.row.authStatus == 2">{{
                statusArr[scope.row.authStatus]
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            align="center"
            width="150"
            label="授权建立时间"
          >
          </el-table-column>
          <el-table-column
            prop="lastFetchNewTime"
            align="center"
            label="最近一次获取新订单的时间"
          >
          </el-table-column>
          <el-table-column
            prop="lastFetchUpdateTime"
            align="center"
            label="最近一次获取有更新的订单时间"
          >
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="120">
            <template slot-scope="scope">

              <el-button
                class="btn"
                v-if="scope.row.platform == 4 "
               @click="switchKttVersion(scope.row, 1)" type="text" size="small"
                >切换到团长版本</el-button
              >

              <el-button
                class="btn"
                v-if="scope.row.platform == 1 "
               @click="switchKttVersion(scope.row, 4)" type="text" size="small"
                >切换到供货商版本</el-button
              >

              <el-button
                class="btn"
                @click="moreConfig(scope.row)"
                type="text"
                size="small"
                v-if="scope.row.platform == 1"
                >更多设置</el-button
              >

              <el-button 
              class="btn"
              @click="deleteShop(scope.row)" type="text" size="small"
                >解除授权</el-button
              >

            </template>
          </el-table-column>
        </el-table>
        <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[20, 40, 50, 100]"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      title="授权渠道"
      :visible.sync="shopflag"
      :before-close="handleClose"
      width="600px"
    >
      <div class="shopLogo">
        <template v-for="(item, index) in shopArr">
          <ol>
            <img :src="item.logo" @click="addShop(item)" />
          </ol>
        </template>
      </div>
    </el-dialog>

    <el-dialog
      title="更多设置"
      :visible.sync="configViewDialogVisible"
      width="600px"
    >
      <el-form :model="config" label-position="right" label-width="200px">
        <el-form-item label="快团团订单下载策略" v-if="config.kttConfig">
          <el-radio-group v-model="config.kttConfig.pullOrderStrategy">
            <el-radio label="ALL">下载全部订单</el-radio>
            <el-radio label="EXCLUDE_HELP">排除帮卖订单</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="configViewDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setConfig(config)">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="添加开团易授权渠道"
      :visible.sync="kaituanyiFlag"
      width="600px"
    >
      <el-form :model="kaituanyiAuth" label-position="right" label-width="80px">
        <el-form-item label="授权码:">
          <el-input
            v-model="kaituanyiAuth.code"
            placeholder="请输入授权码"
          ></el-input>
        </el-form-item>
        <img
          width="400px"
          height="50px"
          style="margin: 20px auto 10px auto"
          src="https://kaituanyi-file.oss-cn-shanghai.aliyuncs.com/logo/WechatIMG160.png"
        />
        <div style="clear: both"></div>
        <label class="kaituanyi-tips"
          >开团易供应商端 -> 系统设置 -> 授权设置 -> 点击查看授权码</label
        >
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="kaituanyiFlag = false">取 消</el-button>
        <el-button type="primary" @click="addKaituanyiShop">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="添加群接龙授权"
      :visible.sync="qunjielongFlag"
      width="600px"
    >
      <el-form
        :model="qunjielongAuth"
        label-position="right"
        label-width="80px"
      >
        <el-form-item label="授权码:">
          <el-input
            v-model="qunjielongAuth.key"
            placeholder="请输入授权码"
          ></el-input>
        </el-form-item>
        <div style="clear: both"></div>
        <label class="kaituanyi-tips"
          >打开群接龙网页版 -> 【应用市场】可订购应用菜单 -> 选择开团易 -> 点击查看授权码</label
        >
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="qunjielongFlag = false">取 消</el-button>
        <el-button type="primary" @click="addQunjielongShop" :loading="loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMall,
  getPlatforms,
  deleteShop,
  setConfig,
  addKaituanyi,
  addQunjielong,
  switchKttVersion
} from "@/api/shop";
import { MessageBox } from "element-ui";
import passWord from "@/components/password";
let moment = require("moment");
export default {
  name: "Orderss",
  components: { passWord },
  data() {
    return {
      searchForm: {
        name: "",
      },
      total: 0,
      page: 1,
      pageSize: 20,
      shopflag: false,      
      shopArr: [],
      loading: false,
      activeName: "first",
      tableData: [],
      platformArr: ["", "快团团团长版", "开团易", "群接龙", "快团团供货商"],
      statusArr: ["未授权", "已授权", "已过期"],
      multipleSelection: [],
      configViewDialogVisible: false,
      config: {
        id: "",
        kttConfig: {},
      },
      kaituanyiFlag: false,
      kaituanyiAuth: {
        code: "",
      },
      qunjielongFlag: false,
      qunjielongAuth: {
        key: "",
      },
    };
  },
  methods: {
    switchKttVersion(row, platform) {
      const param = {
        id: row.id,
        platform: platform
      }
      switchKttVersion(param).then(res => {
        if(res.code === 200) {
          this.$message.success("切换成功")
          this.getTopSearch()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    addKaituanyiShop() {
      const that = this;
      addKaituanyi(this.kaituanyiAuth).then((res) => {
        if (res.code === 200) {
          that.kaituanyiFlag = false;
          that.kaituanyiAuth.code = "";
          that.handleClose();
          that.$message.success(res.message);
        } else {
          that.$message.error(res.message);
        }
      });
    },
    addQunjielongShop() {
      const that = this
      this.loading = true
      addQunjielong(this.qunjielongAuth).then((res) => {
        this.loading = false
        if (res.code === 200) {
          that.qunjielongFlag = false;
          that.qunjielongAuth.key = "";
          that.handleClose();
          that.$message.success(res.message);
        } else {
          that.$message.error(res.message);
        }
      });
    },
    addShop(item) {
      switch (item.code) {
        case "kuaituantuan":
          window.open(item.authUrl);
          break;
        case "kaituanyi":
          this.kaituanyiFlag = true;
          break;
        case "qunjielong":
          this.qunjielongFlag = true;
          break;
        default:
          break;
      }
    },
    setConfig(config) {
      const that = this;
      const param = config;
      setConfig(param).then((res) => {
        console.log(res);
        if (res.code === 200) {
          that.configViewDialogVisible = false;
          this.$message.success(res.message);
          that.getTopSearch();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    moreConfig(row) {
      const that = this;
      that.configViewDialogVisible = true;
      that.config = row.config;
      that.config.id = row.id;
    },
    closeMoreConfig(row) {
      const that = this;
      that.configViewDialogVisible = false;
      that.config = {};
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleClose() {
      this.shopflag = false
      this.loading = false
      this.getTopSearch()
    },
    getTopSearch(e) {
      var that = this;
      var datas = that.searchForm;
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      getMall(datas).then((res) => {
        console.log(res);
        if (res.code === 200) {
          that.tableData = res.data.rows;
          that.total = res.data.totalRows;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getPlat(e) {
      var that = this;
      getPlatforms().then((res) => {
        console.log(res);
        if (res.code === 200) {
          that.shopArr = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    changePage(newPage) {
      var that = this;
      if (that.page === newPage) {
        return;
      }
      that.page = newPage;
      that.getTopSearch();
    },
    shouquan() {
      var that = this;
      that.shopflag = true;
      that.getPlat();
    },
    deleteShop(param) {
      var that = this;
      this.$confirm("解除授权后将无法获取到平台订单, 是否确认解除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteShop(param).then((res) => {
            console.log(res);
            if (res.code === 200) {
              that.getTopSearch();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.getTopSearch();
  },
};
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #07c160;
  color: #fff;
}
.el-tabs__nav-wrap::after {
  height: 1px;
}
.el-form-item__label {
  font-size: 14px;
}

.el-button--primary {
  color: #fff;
  background-color: #07c160;
  border-color: #07c160;
}
.classObj {
  float: right;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
.shopLogo {
  display: block;
  overflow: hidden;
}
.shopLogo ol {
  float: left;
  width: 100px;
  border: 1px solid #eee;
  padding: 5px;
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
}
.shopLogo ol img {
  height: 35px;
  width: 100%;
  display: block;
}
.kaituanyi-tips {
  padding-left: 20px;
  color: red;
}
.btn {
  margin-right: 10px;
}
</style>